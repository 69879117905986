import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { UserRepository } from '@data/user/user.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { appConfig } from '../app.config';

@UntilDestroy()
@Component({
  selector: 'recrewt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = appConfig.app.name;

  isIdle = false;

  isReloadWithId = false;

  loggedIn = false;

  lastPing: Date | null = null;

  constructor(
    private translate: TranslateService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private userRepo: UserRepository,
  ) {
    window.document.title = appConfig.app.title;
    translate.setDefaultLang('de');

    idle.setIdle(5 * 60);
    idle.setTimeout(30 * 60);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.resetOnMove();
    });

    idle.onTimeout.subscribe(() => {
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.isIdle = true;
      this.idle.stop();
    });

    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.userRepo.hasUser$.pipe(untilDestroyed(this)).subscribe((hasUser) => {
      this.loggedIn = hasUser;
    });

    this.reset();
  }

  ngOnInit() {
    if (window.localStorage.getItem('loggedInBeforeUnload')) {
      this.isReloadWithId = true;
      window.localStorage.removeItem('loggedInBeforeUnload');
    }
  }

  logout() {
    this.userRepo.logoutUser();
    this.router.navigate(['/']);
    this.isIdle = false;
    this.isReloadWithId = false;
  }

  reset() {
    this.idle.watch();
    this.isIdle = false;
  }

  @HostListener('window:beforeunload')
  onUnload() {
    window.localStorage.setItem('lastLogin', String(Date.now()));
    if (this.loggedIn) {
      window.localStorage.setItem('loggedInBeforeUnload', 'true');
    }
  }

  private resetOnMove() {
    if (this.isIdle) {
      return;
    }

    if (this.loggedIn) {
      this.reset();
    } else {
      this.idle.stop();
    }
  }
}
