import { createState, select, Store, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { User, UserUpdate } from '@data/user/user.model';
import { UserService } from '@data/user/user.service';
import { defaultIfEmpty, Observable, of, tap } from 'rxjs';
import { SaveState } from '@data/save-state/save-state.model';
import { SaveStateRepository } from '@data/save-state/save-state.store';
import { MatchRepository } from '@data/match/match.store';

interface AuthProps {
  user: User | null;
}

const { state, config } = createState(withProps<AuthProps>({ user: null }));

const authStore = new Store({ name: 'user', state, config });

@Injectable({ providedIn: 'root' })
export class UserRepository {
  hasUser$ = authStore.pipe(
    select((props) => !!props.user),
    defaultIfEmpty(false),
  );

  user$ = authStore.pipe(select((props) => props.user));

  constructor(
    private userService: UserService,
    private saveStateRepo: SaveStateRepository,
    private matchRepo: MatchRepository,
  ) {}

  get user(): User | null {
    return authStore.getValue().user;
  }

  get selectedSaveStateRef(): string | null {
    return authStore.getValue().user?.selectedSaveStateRef ?? null;
  }

  setUser(user: User) {
    authStore.update((props) => ({ ...props, user }));
  }

  loginUser(userId: string | null) {
    this.invalidateCacheOnTimeout();
    // window.localStorage.setItem('lms', String(!!userId));
    if (!userId) {
      userId = window.localStorage.getItem('userId');
    }

    const userData$ = !userId ? this.createUser() : this.loadUser(userId);

    return userData$.pipe(
      tap((userData) => {
        if (userData) {
          window.localStorage.setItem('userId', userData[0].id);
        }
      }),
    );
  }

  logoutUser() {
    authStore.update((props) => ({ ...props, user: null }));
    window.localStorage.clear();
  }

  loadUser(userId: string) {
    return this.userService.get(userId).pipe(tap((data) => this.activateUserWithSaveState(data)));
  }

  createUser() {
    return this.userService.create().pipe(tap((data) => this.activateUserWithSaveState(data)));
  }

  restartStory(): Observable<[User, SaveState] | undefined> {
    localStorage.removeItem('roadmapHintShown');
    localStorage.removeItem('ratedHintShown');
    localStorage.removeItem('matchingHintShown');

    let user = authStore.getValue().user;
    if (!user) {
      return of(undefined);
    }
    return this.userService
      .restartStory(user!.id)
      .pipe(tap((data) => this.activateUserWithSaveState(data)));
  }

  updateUser(user: Partial<UserUpdate>) {
    if (!authStore.getValue().user) {
      throw new Error('User is not logged in');
    }

    return this.userService
      .update(user)
      .pipe(
        tap(() => authStore.update((props) => ({ ...props, user: { ...props.user!, ...user } }))),
      );
  }

  private activateUserWithSaveState(data: [User, SaveState] | undefined) {
    if (data) {
      this.setUser(data[0]);
      this.matchRepo.reset();
      this.saveStateRepo.set([data[1]]);
      this.saveStateRepo.selectSaveState(data[1].id);
    }
  }

  private invalidateCacheOnTimeout() {
    // const isLms = window.localStorage.getItem('lms');
    // if (isLms === 'true') {
    //   return;
    // }

    const lastLogin = window.localStorage.getItem('lastLogin');
    if (!lastLogin) {
      return;
    }

    const diff = Date.now() - parseInt(lastLogin);
    if (diff <= 1000 * 60 * 30 /* 30 minutes */) {
      return;
    }

    this.logoutUser();
  }
}
