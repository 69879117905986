<ng-container *ngIf="!isReloadWithId">
  <router-outlet></router-outlet>
</ng-container>

<recrewt-mascot-dialog
  *ngIf="isIdle"
  [buttonTemplate]="dialogButtons"
  [dismissable]="false"
  message="COMMON.idle"
></recrewt-mascot-dialog>

<ng-template #dialogButtons>
  <div
    class="m-t-2 m-b-1"
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutAlign="center center"
    fxLayoutGap.lt-lg="8px"
  >
    <button (click)="logout()" class="capitalize m-l-2 m-r-2 rounded-button" mat-raised-button>
      <span>{{ 'COMMON.logout' | translate }}!</span>
    </button>
    <button
      (click)="reset()"
      class="capitalize m-l-2 m-r-2 rounded-button"
      color="primary"
      mat-raised-button
    >
      <span>{{ 'COMMON.continue' | translate }}!</span>
    </button>
  </div>
</ng-template>

<recrewt-mascot-dialog
  *ngIf="isReloadWithId"
  [buttonTemplate]="reloadDialogButtons"
  [dismissable]="false"
  message="Willkommen zurück! Es scheint, als ob du die Seite neu geladen hast. Möchtest du weitermachen oder von vorne beginnen?"
></recrewt-mascot-dialog>

<ng-template #reloadDialogButtons>
  <div
    class="m-t-2 m-b-1"
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutAlign="center center"
    fxLayoutGap.lt-lg="8px"
  >
    <button
      (click)="logout()"
      class="capitalize m-l-2 m-r-2 rounded-button"
      color="primary"
      mat-raised-button
    >
      <span>{{ 'Von vorne beginnen' | translate }}!</span>
    </button>
    <button
      (click)="isReloadWithId = false; reset()"
      class="capitalize m-l-2 m-r-2 rounded-button"
      mat-raised-button
    >
      <span>{{ 'COMMON.continue' | translate }}!</span>
    </button>
  </div>
</ng-template>
